import { createStore } from 'vuex'

import { direction } from './data/direction'
import { students } from './data/student'

export default createStore({
  state: {
    url: 'https://qabulapi.namxu.uz',
    month:['Yanvar','Fevral','Mart','Aprel','May','Iyun','Iyul','Avgust','Sentabr','Oktabr','Noyabr','Dekabr']
  },
  getters: {
    month(state){
      return state.month
    },
    url(state){
      return state.url
    }
  },
  modules: {
    direction, 
    students
  }
})
