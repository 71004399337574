import axios from "axios"
export const students = {
  state: () => ({
    student: {
      studyId: '',
    },
    types: [
      { title: 'Kunduzgi', value: 0 },
      { title: 'Sirtqi', value: 1 },
      { title: 'Kechki', value: 2 },
    ],
    // {title: 'Kunduzgi', value: 0},
    country: [
      { name: "O'zbekiston Respublikasi", code: 'uz' },
      { name: "Qozog'iston Respublikasi", code: 'kz' },
      { name: 'Tojikiston Respublikasi', code: 'tj' },
      { name: 'Turkmaniston Respublikasi', code: 'tk' },
      { name: "Qirg'iziston Respublikasi", code: 'kg' },
      { name: 'Boshqa', code: 'ot' },
    ],
    report: {},
    study: [
      'Umumiy o`rta maktab',
      'Kasb-hunar kolleji',
      'Akademik litsey',
      'Universitet',
    ],
    studyName: [
      'Umumiy o`rta maktab',
      'Kasb hunar kolleji',
      'Akademik litsey',
      'Oliy ta`lim muassasi',
    ],
    foreign: ['Ingliz tili', 'Nemis tili', 'Fransuz tili'],
  }),

  getters: {
    report(state) {
      return state.report;
    },
    studyName(state) {
      return state.studyName;
    },
    country(state) {
      return state.country;
    },
    foreign(state) {
      return state.foreign;
    },

    types(state) {
      return state.types;
    },
    study(state) {
      return state.study;
    },
    student(state) {
      return state.student;
    },
  },
  mutations: {
    report(state, payload) {
      state.report = payload;
    },
    student(state, payload) {
      state.student = payload;
    },
  },

  actions: {
    sendSms(context, payload) {
      return axios.post(`${context.getters.url}/student/sendsms`, payload, {
        headers: context.getters.header,
      });
    },
    addStudent(context, payload) {
      return axios.post(
        `${context.getters.url}/student/registration`,
        payload,
        {
          headers: context.getters.header,
        }
      );
    },

    oneStudent(context, payload) {
      return axios
        .get(`${context.getters.url}/student/getone/${payload}`, {
          headers: context.getters.header,
        })
        .then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            context.commit('student', res.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getReport(context, payload) {
      if (payload) {
        axios
          .get(`${context.getters.url}/report/getreport/${payload}`)
          .then((res) => {
              context.commit('report', res.data);
          })
      }
    },
  },
};